import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'

import GlobalStyles from 'components/styleComponents/GlobalStyles'

import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/styleComponents/StripeCheckout'
import LottieShape from 'components/General/LottieShape'
import AbstractPaymentAnimation from 'components/General/Animations/AbstractPaymentAnimation'

import cancel from 'images/png/cancel.png'

const Cancel = () => {
  const { t, i18n, ready } = useTranslation('paymentCancelPage')

  return (
    <>
      {!ready ? null : (
        <>
          <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{t('title')}</title>
          </Helmet>
          <Global styles={GlobalStyles} />
          <main>
            <StripeCheckoutRedirectContainer>
              <ShapeContainer>
                <LottieShape
                  size={500}
                  animationData={AbstractPaymentAnimation}
                />
              </ShapeContainer>
              <StripeCheckoutContentContainer>
                <StripeCheckoutRedirectHeader>
                  <StripeCheckoutImage src={cancel} alt="cancel" />
                  {t('heading')}
                </StripeCheckoutRedirectHeader>
                <StripeCheckoutRedirectDescription>
                  {t('subHeading')}
                </StripeCheckoutRedirectDescription>
                <Link css={{ width: '300px' }} to="/account">
                  <StripeCheckoutRedirectButton>
                    {t('goBackButton')}
                  </StripeCheckoutRedirectButton>
                </Link>
              </StripeCheckoutContentContainer>
            </StripeCheckoutRedirectContainer>
          </main>
        </>
      )}
    </>
  )
}

export default Cancel
