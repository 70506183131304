const AbstractPaymentAnimation = {
  v: '5.2.1',
  fr: 30,
  ip: 0,
  op: 180,
  w: 1920,
  h: 1080,
  nm: 'payment',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'arm',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [88, 378.5, 0], ix: 2 },
            a: { a: 0, k: [72.5, 80, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 145,
          h: 160,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'card',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151, 170.5, 0], ix: 2 },
            a: { a: 0, k: [150, 170, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 300,
          h: 340,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [98.328, 68.673, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.51, 2.3],
                        [0, 2.68],
                        [-3.45, 3.45],
                        [0, 6.89],
                        [-0.76, -3.06],
                        [0, 3.07],
                        [-1.91, -0.77],
                        [0, -5.74],
                        [0, -4.59],
                      ],
                      o: [
                        [-5.21, -1.83],
                        [9.19, -1.53],
                        [3.44, -3.44],
                        [0, -6.89],
                        [0.77, 3.06],
                        [0, -3.06],
                        [1.92, 0.76],
                        [0, 5.74],
                        [0, 4.6],
                      ],
                      v: [
                        [-15.885, 36.426],
                        [-22.395, 26.096],
                        [7.085, -11.044],
                        [8.615, -25.974],
                        [12.055, -26.354],
                        [15.505, -32.484],
                        [20.094, -36.304],
                        [22.395, -26.354],
                        [18.184, -17.934],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [16.962, 192.052, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [10.42, -0.31],
                        [-2.712, -3.423],
                        [-0.342, 4.127],
                        [0, 1.53],
                        [3.32, 4.98],
                      ],
                      o: [
                        [-10.41, 0.3],
                        [3.438, 6.477],
                        [0.442, -5.342],
                        [0, -1.22],
                        [-3.58, 2.04],
                      ],
                      v: [
                        [3.834, -46.018],
                        [-5.666, -33.208],
                        [17.614, -27.258],
                        [37.524, -36.068],
                        [27.574, -52.528],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188234999776, 0.509804010391, 0.729412019253, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Rectangle',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [203.538, 93.566, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [31.129, 21.679], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 5, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.501960784314, 0.501960784314, 0.501960784314, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 50, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: -56.302, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [58.595, 225.051, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.2, -2.4],
                        [-1.41, -1.25],
                      ],
                      o: [
                        [-0.83, 1.17],
                        [0.5, 0.99],
                        [0, 0],
                      ],
                      v: [
                        [-10.079, -1.942],
                        [-11.079, 4.558],
                        [-8.079, 8.118],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.6, -0.4],
                      ],
                      o: [
                        [2, -3.5],
                        [0, 0],
                      ],
                      v: [
                        [-10.077, -1.938],
                        [0.423, -11.938],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.6, -1.2],
                      ],
                      o: [
                        [-0.16, 1.33],
                        [0, 0],
                      ],
                      v: [
                        [-10.079, -1.942],
                        [-8.579, 3.558],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1, -1.5],
                        [0.17, -1],
                        [0.55, -0.94],
                        [2.85, -1.27],
                        [0.93, 0.09],
                      ],
                      o: [
                        [4.5, 0.5],
                        [0.8, 1.2],
                        [-0.35, 0.7],
                        [-2.05, 3.55],
                        [-0.81, 0.36],
                        [0, 0],
                      ],
                      v: [
                        [0.423, -11.942],
                        [10.923, -4.942],
                        [11.423, -0.442],
                        [10.063, 2.058],
                        [1.923, 11.558],
                        [-0.707, 11.928],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.423, -11.942],
                        [-3.577, -6.442],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-8.077, 8.117],
                        [-3.577, 2.057],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-2.52, -0.26],
                      ],
                      o: [
                        [2.09, 1.85],
                        [0, 0],
                      ],
                      v: [
                        [-8.077, 8.117],
                        [-0.707, 11.927],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-4, 2.8],
                        [0, 0],
                      ],
                      o: [
                        [1, 2],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.577, 2.057],
                        [4.423, 4.557],
                        [-0.707, 11.927],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.4, 0.95],
                      ],
                      o: [
                        [-0.83, -0.21],
                        [0, 0],
                      ],
                      v: [
                        [-3.578, 2.057],
                        [-5.408, 0.057],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.37, -0.88],
                      ],
                      o: [
                        [0.08, 0.64],
                        [0, 0],
                      ],
                      v: [
                        [-6.072, -2.443],
                        [-5.412, 0.057],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 10,
                  ty: 'sh',
                  ix: 11,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.67, -0.53],
                      ],
                      o: [
                        [0.35, 0.44],
                        [0, 0],
                      ],
                      v: [
                        [0.423, -4.443],
                        [2.053, -2.813],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 11,
                  ty: 'sh',
                  ix: 12,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.8, -2.4],
                      ],
                      o: [
                        [1, 0.5],
                        [0, 0],
                      ],
                      v: [
                        [3.923, -1.942],
                        [5.923, 2.558],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 12,
                  ty: 'sh',
                  ix: 13,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.923, -1.943],
                        [6.923, -6.443],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 13,
                  ty: 'sh',
                  ix: 14,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.62, 0.49],
                      ],
                      o: [
                        [-0.58, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.926, -1.937],
                        [2.056, -2.817],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 14',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 14,
                  ty: 'sh',
                  ix: 15,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-5.408, 0.057],
                        [-0.078, -7.443],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 15',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 15,
                  ty: 'sh',
                  ix: 16,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.053, -2.813],
                        [-0.577, 1.057],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 16',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.501960784314, 0.501960784314, 0.501960784314, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 50, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  ml2: { a: 0, k: 4, ix: 8 },
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 18,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [121.559, 198.055, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.85, -5.73],
                        [-22.89, -15.83],
                        [-2.36, 14.49],
                      ],
                      o: [
                        [-5.46, 34.4],
                        [-46, -51.21],
                        [-3.73, 5.56],
                      ],
                      v: [
                        [-30.684, -36.745],
                        [31.146, 53.745],
                        [-19.264, -53.745],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.501960784314, 0.501960784314, 0.501960784314, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 50, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [150.631, 169.688, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.6, -2.4],
                        [1, -1.33],
                        [0, 0],
                        [0, 0],
                        [1.6, -2.4],
                        [1.5, 1],
                        [0, 0],
                        [0, 0],
                        [2.4, 1.6],
                        [-0.5, 1.67],
                        [-13.68, 20.35],
                        [-3.73, 5.56],
                        [-1.28, 2.13],
                        [-1.83, -1.17],
                      ],
                      o: [
                        [3.6, 2.4],
                        [0, 0],
                        [0, 0],
                        [-3.13, 5.27],
                        [-1.6, 2.4],
                        [0, 0],
                        [0, 0],
                        [-13.33, -9],
                        [-2.4, -1.6],
                        [10.12, -15.03],
                        [3.86, -5.73],
                        [19.48, -29],
                        [2.4, -4],
                        [23.33, 15.83],
                      ],
                      v: [
                        [83.701, -43.723],
                        [85.201, -36.723],
                        [13.701, 70.767],
                        [10.701, 75.277],
                        [-0.679, 94.087],
                        [-7.179, 94.087],
                        [-28.049, 80.777],
                        [-41.299, 72.277],
                        [-84.299, 43.277],
                        [-85.799, 36.277],
                        [-48.129, -19.723],
                        [-36.709, -36.723],
                        [0.701, -92.723],
                        [9.201, -94.223],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [
                      0.792156862745098,
                      0.792156862745098,
                      0.792156862745098,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [166.667, 166.667], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [905.779, 483.282, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.44, 5.36],
                    [1.91, -2.3],
                    [6.74, -8.27],
                    [-4.22, -5.36],
                    [0, 1.92],
                    [0.39, 0.76],
                    [-0.39, 0.77],
                    [0, -3.06],
                    [-0.76, -3.44],
                    [-6.12, 1.53],
                    [1.54, 3.45],
                    [4.59, -3.44],
                    [1.91, 2.68],
                    [-1.91, 1.53],
                    [-1.53, 0],
                    [-2.68, 0.77],
                  ],
                  o: [
                    [-2.76, -4.29],
                    [-4.6, -0.51],
                    [-8.42, 10.33],
                    [4.21, 5.36],
                    [0, -1.91],
                    [-0.38, -0.77],
                    [0.38, -0.76],
                    [0, 3.06],
                    [0.77, 3.45],
                    [6.13, -1.53],
                    [-1.53, -3.44],
                    [-4.6, 3.45],
                    [-1.92, -2.68],
                    [1.91, -1.53],
                    [1.54, 0],
                    [2.68, -0.76],
                  ],
                  v: [
                    [13.384, -21.165],
                    [4.194, -19.635],
                    [-18.016, -10.825],
                    [-9.586, 20.565],
                    [-3.086, 19.795],
                    [-7.296, 12.145],
                    [-9.586, 4.865],
                    [-6.146, 5.635],
                    [-3.086, 17.115],
                    [15.674, 19.795],
                    [19.884, 7.545],
                    [6.494, 7.545],
                    [-1.546, 5.635],
                    [0.364, -2.405],
                    [2.274, -8.915],
                    [11.084, -8.915],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.294117987156, 0.325489997864, 0.380391985178, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [919.785, 592.022, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9.95, -5.36],
                    [2.43, 6.38],
                    [0, 0],
                    [-3.06, 2.29],
                    [-0.76, 2.68],
                    [-2.68, 0],
                    [0, 0],
                    [0, -1.53],
                    [1.15, -3.82],
                    [1.53, -1.53],
                  ],
                  o: [
                    [-7.97, 4.29],
                    [0, 0],
                    [4.21, 0],
                    [3.06, -2.3],
                    [0.77, -2.68],
                    [0, 0],
                    [2.68, 0],
                    [0, 1.53],
                    [-1.15, 3.83],
                    [-1.54, 1.53],
                  ],
                  v: [
                    [-8.803, 31.502],
                    [-26.033, 17.722],
                    [-16.843, 17.722],
                    [7.657, -14.048],
                    [11.097, -29.748],
                    [20.287, -32.428],
                    [20.287, -32.428],
                    [26.037, -27.068],
                    [21.057, -22.478],
                    [16.467, -15.198],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [912.934, 501.777, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.53, 0.39],
                    [4.21, 0.93],
                    [-1.28, -6.2],
                    [-1.73, -1.04],
                    [-1.15, -1.53],
                    [0, -6.12],
                    [-1.53, 0.76],
                    [0.38, 8.42],
                    [-1.91, 2.68],
                    [0.77, 0.77],
                    [-1.92, 0.76],
                  ],
                  o: [
                    [-1.23, -0.3],
                    [-15.31, -3.37],
                    [-4.3, -0.85],
                    [1.91, 1.15],
                    [1.15, 1.54],
                    [1.53, 7.28],
                    [1.53, -0.77],
                    [-0.38, -8.42],
                    [1.92, -2.68],
                    [-0.76, -0.76],
                    [1.91, -0.77],
                  ],
                  v: [
                    [12.152, -11.208],
                    [3.342, -21.928],
                    [-11.968, -7.548],
                    [-13.118, 0.282],
                    [-8.908, 7.932],
                    [-8.138, 17.122],
                    [2.582, 21.722],
                    [5.642, 12.532],
                    [14.062, 1.812],
                    [12.912, -4.698],
                    [12.152, -8.138],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [922.962, 602.552, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [10.42, -0.31],
                    [2.68, -0.89],
                    [0.38, -10.34],
                    [-0.39, -4.98],
                    [1.28, -10.85],
                    [-17.1, 1.27],
                    [0, 5.36],
                    [4.548, 7.797],
                  ],
                  o: [
                    [-10.41, 0.3],
                    [-14.17, 7.28],
                    [-0.39, 10.33],
                    [0.3, 3.98],
                    [3.37, 3.06],
                    [1.15, -3.83],
                    [0, -5.36],
                    [-2.652, -2.853],
                  ],
                  v: [
                    [3.834, -46.018],
                    [-17.216, -42.958],
                    [-37.506, -11.178],
                    [-30.616, 13.322],
                    [-34.066, 50.842],
                    [21.444, 50.842],
                    [17.614, -27.258],
                    [19.624, -40.228],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.188234999776, 0.509804010391, 0.729412019253, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [904.794, 791.333, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.43, 15.31],
                    [0, 0],
                    [-1.78, -58.38],
                    [4.53, 2.06],
                    [0, 3.98],
                    [2.81, 8.8],
                    [0, -9.57],
                    [3.06, 3.9],
                    [-3.73, 6.91],
                  ],
                  o: [
                    [0, 0],
                    [6.02, 8.03],
                    [-9.71, 7.08],
                    [-0.36, -7.73],
                    [0, -12.56],
                    [-9.57, 19.9],
                    [-8.77, 4.68],
                    [3.02, -8.92],
                    [13.02, -24.12],
                  ],
                  v: [
                    [-19.331, -68.8],
                    [28.139, -68.8],
                    [39.48, 64.04],
                    [13.74, 66.7],
                    [13.21, 48.35],
                    [4.779, 6.24],
                    [-19.331, 64.04],
                    [-39.48, 58.2],
                    [-29.29, 33.8],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078014374, 0.549019992352, 0.549019992352, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: 'comp',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 0,
              s: [0],
              e: [20],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 50,
              s: [20],
              e: [-12.692],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 100,
              s: [-12.692],
              e: [22.24],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 150,
              s: [22.24],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [943, 549, 0], ix: 2 },
        a: { a: 0, k: [52, 436.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 300,
      h: 461,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [-73.022],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 13,
              s: [-73.022],
              e: [-142],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [-142],
              e: [-237.313],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [-237.313],
              e: [-317.323],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 135,
              s: [-317.323],
              e: [-360],
            },
            { t: 179 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [974.365, 908.89, 0],
              e: [908.365, 833.64, 0],
              to: [6.83333349227905, -28.875, 0],
              ti: [91.147575378418, 3.53453969955444, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [908.365, 833.64, 0],
              e: [798.121, 875.392, 0],
              to: [-65.068717956543, -2.52324819564819, 0],
              ti: [4.59102773666382, -36.4046669006348, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [798.121, 875.392, 0],
              e: [880.734, 935.829, 0],
              to: [-5.39988040924072, 42.8184852600098, 0],
              ti: [-36.0812377929688, -4.10384225845337, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 135,
              s: [880.734, 935.829, 0],
              e: [974.365, 908.89, 0],
              to: [48.3309020996094, 5.49710607528687, 0],
              ti: [-4.10515213012695, 18.2398891448975, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [9.75, 6.75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3, -11],
                    [9.17, 0.33],
                    [-0.5, 4.5],
                  ],
                  o: [
                    [-2.4, 8.8],
                    [25, 7],
                    [0.5, -4.5],
                  ],
                  v: [
                    [11.731, -1.785],
                    [-18.769, 8.215],
                    [18.731, -6.285],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.38431372549, 0.839215686275, 0.788235294118, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [-97],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [-97],
              e: [-192.6],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [-192.6],
              e: [-274.45],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 135,
              s: [-274.45],
              e: [-360],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [818.418, 889.877, 0],
              e: [909.418, 934.127, 0],
              to: [4.08333349227905, 39.1875, 0],
              ti: [-31.8438549041748, -0.11920403689146, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [909.418, 934.127, 0],
              e: [1009.268, 880.827, 0],
              to: [64.5583343505859, 0.24166716635227, 0],
              ti: [0.62465214729309, 35.3198738098145, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1009.268, 880.827, 0],
              e: [906.806, 834.715, 0],
              to: [-0.57162147760391, -32.321346282959, 0],
              ti: [74.3113632202148, -0.35334038734436, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 135,
              s: [906.806, 834.715, 0],
              e: [818.418, 889.877, 0],
              to: [-68.3424224853516, 0.32495886087418, 0],
              ti: [0.18952360749245, -28.2145805358887, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-4.25, 1.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4, 7],
                    [-2.67, 4.17],
                    [-3, -6],
                  ],
                  o: [
                    [-3.2, -5.6],
                    [-6, 0],
                    [3, 6],
                  ],
                  v: [
                    [0.901, 6.474],
                    [4.901, -13.026],
                    [-4.099, 9.474],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.38431372549, 0.839215686275, 0.788235294118, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1220.411, 870.116, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    n: '0p667_1_0p333_0',
                    t: 0,
                    s: [
                      {
                        i: [
                          [-2.48, -7.43],
                          [-4.97, 0.28],
                          [-3.31, -10.57],
                          [8.01, 0.28],
                          [59.57, -0.22],
                          [-14.485, 15.514],
                          [-0.357, 0.934],
                          [-0.813, 2.381],
                          [-11.87, -4.4],
                        ],
                        o: [
                          [1.99, 5.95],
                          [2.21, 4.41],
                          [3.32, 10.58],
                          [-44.04, 0.26],
                          [28.099, -0.092],
                          [10.117, -10.836],
                          [0.898, -2.347],
                          [10.6, -31.06],
                          [-14.91, 3.31],
                        ],
                        v: [
                          [62.315, 4.891],
                          [79.715, 11.501],
                          [90.485, 37.931],
                          [70.605, 50.321],
                          [-90.955, 51.081],
                          [-28.437, 25.21],
                          [-4.04, -16.093],
                          [-1.475, -23.189],
                          [47.405, -48.799],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [-2.48, -7.43],
                          [-4.97, 0.28],
                          [-3.31, -10.57],
                          [8.01, 0.28],
                          [59.57, -0.22],
                          [-21.605, 10.79],
                          [-14.346, 22.451],
                          [0.658, 17.134],
                          [-11.87, -4.4],
                        ],
                        o: [
                          [1.99, 5.95],
                          [2.21, 4.41],
                          [3.32, 10.58],
                          [-44.04, 0.26],
                          [25.744, -0.084],
                          [23.113, -11.544],
                          [13.404, -29.599],
                          [17.758, -24.866],
                          [-14.91, 3.31],
                        ],
                        v: [
                          [62.315, 4.891],
                          [79.715, 11.501],
                          [90.485, 37.931],
                          [70.605, 50.321],
                          [-112.671, 51.134],
                          [-113.041, 4.74],
                          [-59.551, -11.571],
                          [-86.705, -46.303],
                          [47.405, -48.799],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    n: '0p667_1_0p333_0',
                    t: 83,
                    s: [
                      {
                        i: [
                          [-2.48, -7.43],
                          [-4.97, 0.28],
                          [-3.31, -10.57],
                          [8.01, 0.28],
                          [59.57, -0.22],
                          [-21.605, 10.79],
                          [-14.346, 22.451],
                          [0.658, 17.134],
                          [-11.87, -4.4],
                        ],
                        o: [
                          [1.99, 5.95],
                          [2.21, 4.41],
                          [3.32, 10.58],
                          [-44.04, 0.26],
                          [25.744, -0.084],
                          [23.113, -11.544],
                          [13.404, -29.599],
                          [17.758, -24.866],
                          [-14.91, 3.31],
                        ],
                        v: [
                          [62.315, 4.891],
                          [79.715, 11.501],
                          [90.485, 37.931],
                          [70.605, 50.321],
                          [-112.671, 51.134],
                          [-113.041, 4.74],
                          [-59.551, -11.571],
                          [-86.705, -46.303],
                          [47.405, -48.799],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [-2.48, -7.43],
                          [-4.97, 0.28],
                          [-3.31, -10.57],
                          [8.01, 0.28],
                          [59.57, -0.22],
                          [-14.485, 15.514],
                          [-0.357, 0.934],
                          [-0.813, 2.381],
                          [-11.87, -4.4],
                        ],
                        o: [
                          [1.99, 5.95],
                          [2.21, 4.41],
                          [3.32, 10.58],
                          [-44.04, 0.26],
                          [28.099, -0.092],
                          [10.117, -10.836],
                          [0.898, -2.347],
                          [10.6, -31.06],
                          [-14.91, 3.31],
                        ],
                        v: [
                          [62.315, 4.891],
                          [79.715, 11.501],
                          [90.485, 37.931],
                          [70.605, 50.321],
                          [-90.955, 51.081],
                          [-28.437, 25.21],
                          [-4.04, -16.093],
                          [-1.475, -23.189],
                          [47.405, -48.799],
                        ],
                        c: true,
                      },
                    ],
                  },
                  { t: 180 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.38431372549, 0.839215686275, 0.788235294118, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [929.352, 853.109, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.62, -1.32],
                    [-0.82, -6.88],
                    [10.61, -31.06],
                    [52.2, -0.17],
                    [10.55, 1.37],
                    [0, 6.06],
                    [-3.98, 10.57],
                    [-7.74, 0.55],
                  ],
                  o: [
                    [8.61, 1.32],
                    [-11.87, -4.4],
                    [-13.25, 38.82],
                    [-133.96, 0.48],
                    [-15.25, -1.98],
                    [4.69, -24.23],
                    [3.97, -10.57],
                    [124.27, -0.55],
                  ],
                  v: [
                    [208.789, -60.893],
                    [222.039, -38.593],
                    [173.159, -12.983],
                    [83.678, 61.287],
                    [-202.982, 60.527],
                    [-222.042, 39.877],
                    [-202.982, -46.023],
                    [-174.811, -60.893],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.2901960784313726,
                  0.5647058823529412,
                  0.8862745098039215,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [959.167, 868.333, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.21, -5.51],
                    [0, 0],
                    [-0.83, -11.56],
                    [9.39, -0.83],
                    [0, 0],
                    [0, 11.84],
                    [-5.3, 11.23],
                    [-9.95, -0.27],
                    [-11.27, -0.66],
                  ],
                  o: [
                    [0, 0],
                    [1.65, 4.96],
                    [1.99, 18.51],
                    [0, 0],
                    [-18.56, 0],
                    [6.9, -30.28],
                    [5.3, -11.23],
                    [123.17, -0.27],
                    [11.26, 0.67],
                  ],
                  v: [
                    [211.61, -59.29],
                    [253.87, 30.74],
                    [256.35, 51.39],
                    [230.67, 77],
                    [-233.3, 77],
                    [-256.5, 41.48],
                    [-229.16, -63.42],
                    [-192.7, -76.64],
                    [190.9, -76.64],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.278431372549, 0.541176470588, 0.694117647059, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0.539, 0.547], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [166.667, 166.667], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default AbstractPaymentAnimation
